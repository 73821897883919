
import aaron from '../../assets/memberPhotos/Aaron.JPG'
import alex from '../../assets/memberPhotos/Alex.JPG'
import usha from '../../assets/memberPhotos/Usha.JPG'
import ayman from '../../assets/memberPhotos/Ayman.JPG'
import ido from '../../assets/memberPhotos/Ido.JPG'
import max from '../../assets/memberPhotos/Max.JPG'
import razeen from '../../assets/memberPhotos/Razeen.JPG'
import travis from '../../assets/memberPhotos/Travis.JPG'
import prottoy from '../../assets/memberPhotos/Prottoy.JPG'
import none from '../../assets/memberPhotos/none.png'

const teamDetails = [
	{
		id: 1,
		name: 'Max',
		role: 'President',
		bio: 'I am Maksym Woychyshyn, President of the Robotics club at UTM! I originally became interested in robotics in high school, and this has led to me studying CompSci Robotics courses at UTM. Looking forward to the semester!',
		image: max,
		linkedin: 'https://www.linkedin.com/in/maksym-woychyshyn-a14836197/',
		github: ''
	},
	{
		id: 2,
		name: 'Usha',
		role: 'Exec Communication',
		bio: 'I\'ve lived in six countries.',
		image: usha,
		linkedin: 'https://www.linkedin.com/in/ushasj/',
		github: 'https://github.com/usha-sj/'
	},
	{
		id: 3,
		name: 'Razeen',
		role: 'Exec Events',
		bio: '3rd year CS and Math student. I enjoy working on robotics, automation and Software Dev.',
		image: razeen,
		linkedin: 'https://www.linkedin.com/in/razeenali/',
		github: 'https://github.com/r4z33n4l1'
	},
	{
		id: 4,
		name: 'Travis',
		role: 'Exec Finance',
		bio: 'Hi! My name is Travis, a fifth year CS Spec. I\'ll be your finance exec this remaining semester.',
		image: travis,
		linkedin: 'https://www.linkedin.com/in/travis-shao-442620173/',
		github: 'https://github.com/FourFourSquareSquare'
	},
	{
		id: 5,
		name: 'Ido',
		role: 'Exec Coordination',
		bio: 'I used to have two cats.',
		image: ido,
		linkedin: 'https://www.linkedin.com/in/idobenhaim/',
		github: 'https://github.com/ggggg'
	},
	{
		id: 6,
		name: 'Alex Apostolu',
		role: 'Tech',
		bio: 'Executive member of the UofT Bees Club.',
		image: alex,
		linkedin: 'https://www.linkedin.com/in/apostolu/',
		github: 'https://www.github.com/alexapostolu'
	},
	{
		id: 8,
		name: 'Damon',
		role: 'Tech',
		bio: '',
		image: none,
		linkedin: 'https://www.linkedin.com/in/damon-d-ma/',
		github: 'https://github.com/Damon-D-Ma'
	},
	{
		id: 9,
		name: 'Yajan',
		role: 'Tech',
		bio: 'Music (Played Drums for 5yrs), Programming/Engineering, JiuJitsu/Muay Thai',
		image: none,
		linkedin: 'https://www.linkedin.com/in/yajansingh/',
		github: 'https://github.com/yajan-singh'
	},
	{
		id: 7,
		name: 'Ayman Mohammed',
		role: 'Graphics',
		bio: 'I sleep excessively, code obsessively, and amass my divine inspirations within the universal malloc.',
		image: ayman,
		linkedin: 'https://github.com/omnipotentsock',
		github: 'https://www.linkedin.com/in/ayman-ahmed-mohammed'
	},
	{
		id: 10,
		name: 'Aaron',
		role: 'Logistics',
		bio: 'Always striving to be the most satisfying partner to work with, and always excited for the passionate talks with problem-solvers and dreamers, so come chat!',
		image: aaron,
		linkedin: 'https://www.linkedin.com/in/aaronwu000/',
		github: 'https://github.com/aaronwu001'
	},
	{
		id: 11,
		name: 'Prottoy',
		role: 'Events',
		bio: 'I have never once lost a game of thumb wars.',
		image: prottoy,
		linkedin: '',
		github: ''
	},
	{
		id: 12,
		name: 'Daksh',
		role: 'Mentor',
		bio: '',
		image: none,
		linkedin: 'https://www.linkedin.com/in/daksh-malhotra',
		github: 'https://github.com/DakshChan'
	},
	{
		id: 13,
		name: 'Hamza',
		role: 'Mentor',
		bio: 'I love talking about all sorts of fun and interesting topics, so feel free to reach out to me @hamza.pp on discord to chat!',
		image: none,
		linkedin: '',
		github: 'https://github.com/gondalm1'
	},
	{
		id: 14,
		name: 'Sammy',
		role: 'Mentor',
		bio: 'I love low-level development and tinkering around with hardware, and I also love languages, both natural and programming. Feel free to say hi!',
		image: none,
		linkedin: '',
		github: ''
	}
];

export const MEMBERS = teamDetails;
export default teamDetails;