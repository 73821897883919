import sample1 from '../../assets/sample1.jpg';
import sample2 from '../../assets/sample2.jpg';
import sample3 from '../../assets/sample3.jpg';
import sample4 from '../../assets/sample4.jpeg';

const eventDetails = [
	{
		id: 1,
		name: "General Meeting: Exploring the world of Battle Bots!",
		image: sample1,
		venue: "MN1380",
		description: "Navigation, localization, control, planning, perception - ever wonder how robots all of these together efficiently? Join us to learn more about one of the most popular robotics framework used in industry!",
		host: "Jerma",
		date: "02/14/23",
		startTime: "8:00 AM",
		url: `https://github.com/omnipotentsock`
	},
	{
		id: 2,
		name: "The Floor is Lava! Beginner Arduino Workshop.",
		image: sample2,
		venue: "Online",
		description: "Unveiling our SECRET WORKSHOP: The Floor is Lava! 🕹️✨ Join us as we dive into the world of sensors and unleash your creativity to create the ultimate game - using Arduino gyroscopes!",
		host: "Jerma",
		date: "02/14/23",
		startTime: "8:00 AM",
		url: `https://github.com/omnipotentsock`
	},
	{
		id: 3,
		name: "Speaker Series: Robot Guide Dog!",
		image: sample3,
		venue: "O/MN1380",
		description: "They literally got that DAWG in them fr.",
		host: "Jerma",
		date: "02/14/23",
		startTime: "8:00 AM",
		url: `https://github.com/omnipotentsock`
	},
	{
		id: 4,
		name: "Nuclear Reactor Series: A series where CRIME.",
		image: sample4,
		venue: "MN1380",
		description: "Come with us to make a full hydrogen atomic bomb in 17 minutes! Ask PETA. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		host: "Jerma",
		date: "02/14/23",
		startTime: "8:00 AM",
		url: `https://github.com/omnipotentsock`
	}
]

export const EVENTS = eventDetails;
export default eventDetails;